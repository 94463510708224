<template>
    <div>
        <ul>
            <li v-for="error,i in errors" :key="i">{{error}}</li>
        </ul>
        <div class="table-responsive">
            <table class="table text-center">
            <thead>
                <tr>
                    <th scope="col" v-for="(s,i) in numberOfSelect" :key="i">   
                        <e-select
                        track-by="text"
                        label="text"
                        :options="options"
                        :searchable="true"
                        :allow-empty="false"
                        :search="true"
                        v-model="selected[i]"
                        style="min-width: 15em;"
                       >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                            <template slot="option" slot-scope="{ option}">
                                {{ option.text }}
                            </template>
                        </e-select>
                    </th>
                    <th scope="col">{{ $t('tiers.tiers_import.ligne_importer') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(ligne, i) in form" :key="i">
                    <td scope="col" v-for="(cell,j) in ligne" :key="j">
                        <p v-if="j < Object.keys(ligne).length">{{cell}}</p>
                        <input v-else type="checkbox" v-model="ligne.checked">
                    </td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="text-center">
            <b-button v-if="!processing" variant="primary" @click="importData()">{{ $t('tiers.tiers_import.tiers_importer') }}</b-button>
            <LoadingSpinner v-else class="p-0 d-inline" />
        </div>
    </div>
</template>
<script>
import Navigation from "@/mixins/Navigation.js"
import Accounting from "@/mixins/Accounting.js"
import _deepClone from 'lodash/cloneDeep'

export default {
    name: 'Import',
	mixins: [Navigation, Accounting],
	props: ['numberOfSelect', 'form', 'options'],
    data() {
        return {
            errors : [],
            selected: {},
            processing: false
        }
	},
	created() {
		this.init_component()
	},
    methods: {
        init_component(){
            this.selected = _deepClone(this.options)
            let selectLength = Object.keys(this.selected).length
            while(selectLength < this.numberOfSelect){
                this.selected[selectLength] = { value: 'null', text: 'ne pas importer', column: 'null', requis: false}
                selectLength++
            }
        },
        async importData() {
            this.processing = true
            if (this.checkform() == false){
                this.processing = false
                return
            }
            const newform = []
            let i = 0
            for (const elements of this.form) { //parcour tableau
                if (elements.checked == true){
                    newform[i] = {}
                    let x = 0
                    for (const [key, value] of Object.entries(elements)) { //parcour objet
                        if (key != 'checked'){
                            if (this.selected[x].value != 'null'){ //options.value
                                const columnName = this.selected[x].column
                                newform[i][columnName] = value 
                            }
                            x++
                        }
                    }
                    i++
                }
            }
            this.$emit('clicked', newform)
        },
        checkform(){
            this.errors = []
            let verfificationArray = []
            for (const [key, value] of Object.entries(this.selected)){
                if (key == this.numberOfSelect){
                    break
                }
                if(value.value != 'null'){
                    if (verfificationArray.includes(value.value) == false){
                        verfificationArray.push(value.value)
                    } else {
                        this.errors.push(this.getTrad('tiers.tiers_import.avertissement_colonnes') + value.text)
                        return false
                    }
                }
            }
            for (const opt of this.options){
                if(opt.value != 'null' && opt.requis == true){
                    if (!verfificationArray.includes(opt.value)){
                        this.errors.push(this.getTrad('tiers.tiers_import.avertissement_requis') + opt.text)
                    }
                }
            }
            if (this.errors.length > 0){
                return false
            }
            return true
        }
    },
    components: {
        LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),            
    },
}
</script>
